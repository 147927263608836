import React from 'react'
import Checkbox from '../../../../../components/inputs/checkbox/checkbox.component'

const Form = props => {
    return (
        <form onSubmit={props.onSubmit}>
            <div className="inputs">
                <div className="row" id="file">
                    <div className="form-input">
                        <input type="file" name="file" onChange={e => {
                            if (e.target.files.length > 0) {
                                props.onChange({
                                    target: {
                                        name: "file",
                                        value: e.target.files[0]
                                    }
                                })
                            }
                        }} />
                        <span className="error">{props.errors.file}</span>
                    </div>
                </div>
                {
                    !props.autoExtractToken && <>
                        <div className="row" id="token">
                            <div className="form-input">
                                <input type="number" name="token" placeholder="Token" onChange={props.onChange} />
                                <span className="error">{props.errors.token}</span>
                            </div>
                        </div>
                    </>
                }
                <div className="row-checkbox">
                    <Checkbox text="Auto Extract Token" name="autoExtractToken" checked={props.autoExtractToken} onChange={() => {
                        props.onChange({
                            target: {
                                name: "autoExtractToken",
                                value: !props.autoExtractToken
                            }
                        })
                    }} />
                </div>
            </div>
            <div className="triggers">
                <button type="submit">Upload</button>
                <button type="reset" onClick={props.onCancel}>Cancel</button>
            </div>
        </form>
    )
}

export default Form