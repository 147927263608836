import React from 'react'
import './alert.styles.scss'
import Dialog from '../dialog.component'
import imageError from '../../../assets/images/error.png'

const AlertDialog =  props => {
    return (
        <Dialog>
            <div className="alert">
                <h3>{props.title}</h3>
                <img src={imageError} alt="" onClick={props.onClose}/>
                <div>
                    <p>{props.message}</p>
                    <button onClick={props.onClick}>{props.action}</button>
                </div>
            </div>
        </Dialog>
    )
}

export default AlertDialog