import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import './app.styles.scss'
import NotificationTemplate from './templates/notification/notification.template'
import Splash from './pages/splash/splash.page'
import NotificationController from './pages/notification/notification.controller'

const AppController = () => {
    return (
        <Router>
            <Switch>
                <Route path="/notification" component={() => <NotificationTemplate><NotificationController /></NotificationTemplate>} />
                <Route exact path="/" component={() => <Splash />} />
            </Switch>
        </Router>
    )
}

export default AppController