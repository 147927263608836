import React from 'react';
import ReactDOM from 'react-dom';
import store from './redux/stores/store'
import { Provider } from 'react-redux'
import './assets/fonts/boing-regular.otf'
import './assets/fonts/boing-light.otf'
import './index.styles.scss';
import AppController from './app.controller';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AppController/>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
