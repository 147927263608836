import React from 'react'
import { Switch, Route } from "react-router-dom"
import Order from './order/order.page'

const NotificationController = () => {
    return (
        <div className="notification">
            <Switch>
                <Route path="/notification/order/:token" component={Order} />
            </Switch>
        </div>
    )
}

export default NotificationController