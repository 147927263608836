import Requests from "../../../../../utils/Requests"
import { StringValidationRule } from "../../../../../data/validation/rules"
import Validation from "../../../../../data/validation/validation"

const getDefaultState = () => {
    return {
        reason: "",
        saving: false,
        showError: false,
        errors: {},
        alertError: {}
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.reason, (error) => errors.reason = error, { min: { value: 2, error: "Invalid" }, max: { value: 100, error: "Invalid" }  })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const rejectOrder = instance => {

    let data = {
        token: instance.props.match.params.token,
        reason: instance.state.reason
    }

    Requests(instance.props)
        .post()
        .withUrl("/vendor/order/authorization/cancel")
        .withData(data)
        .then(response => {
            handleRejectOrderResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleRejectOrderResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                saving: false,
            })
            instance.props.onReload()
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        saving: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Okay",
            onClick: () => instance.setState({
                ...instance.state,
                showError: false
            }),
            onClose: () => instance.setState({
                ...instance.state,
                showError: false
            })
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(),
        rejectOrder: () => rejectOrder(instance),
        isValid: () => isValid(instance)
    }
}

export default Service