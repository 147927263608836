import Requests from "../../../../../utils/Requests"

const getDefaultState = () => {
    return {
        updating: false,
        showError: false,
        alertError: {}
    }
}

const updateStatus = (instance, status) => {
    let data = {
        token: instance.props.match.params.token,
        status: status
    }

    Requests(instance.props)
        .post()
        .withUrl("/vendor/order/authorization/update/status")
        .withData(data)
        .then(response => {
            handleUpdateStatusResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleUpdateStatusResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                updating: false,
            })
            instance.props.onReload()
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        updating: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Okay",
            onClick: () => instance.setState({
                ...instance.state,
                showError: false
            }),
            onClose: () => instance.setState({
                ...instance.state,
                showError: false
            })
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(),
        updateStatus: status => updateStatus(instance, status)
    }
}

export default Service