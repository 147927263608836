import Requests from "../../../../../utils/Requests"
import { StringValidationRule } from "../../../../../data/validation/rules"
import Validation from "../../../../../data/validation/validation"

const getDefaultState = () => {
    return {
        receipt: "",
        token: "",
        updating: false,
        showError: false,
        errors: {},
        alertError: {}
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.receipt, (error) => errors.receipt = error, { min: { value: 3, error: "Invalid" }, max: { value: 50, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.token, (error) => errors.token = error, { min: { value: 0, error: "Invalid" }, max: { value: 20, error: "Invalid" } })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const updateReceipt = instance => {
    let data = {
        receipt: instance.state.receipt,
        token: instance.props.match.params.token
    }

    Requests(instance.props)
        .post()
        .withUrl("/vendor/order/authorization/cabletv/receipt")
        .withData(data)
        .then(response => {
            handleUpdateReceiptResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleUpdateReceiptResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                updating: false,
            })
            instance.props.onReload()
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        updating: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Okay",
            onClick: () => instance.setState({
                ...instance.state,
                showError: false
            }),
            onClose: () => instance.setState({
                ...instance.state,
                showError: false
            })
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(),
        updateReceipt: () => updateReceipt(instance),
        isValid: () => isValid(instance)
    }
}

export default Service