import { MENU_ACTIVE_ITEM } from './action-types'

const Reducer = (state = [], action) => {
    switch (action.type) {
        case MENU_ACTIVE_ITEM:
            return activeItem(state, action.payload)
        default:
            return state
    }
}

const activeItem = (state, payload) => {
    state.activeItem = payload.key
    return state
}

export default Reducer