import React from 'react'
import './notification.styles.scss'
import '../form.styles.scss'

const NotificationTemplate = (props) => {
    return (
        <>
            <section className="notification">
                <div>
                    <div className="container">
                        {props.children}
                        <footer>
                            <p>© 2022 Skysenx Limited. All rights reserved.</p>
                        </footer>
                    </div>
                </div>
                <div>
                    <div className="shade"></div>
                </div>
            </section>
        </>
    )
}

export default NotificationTemplate