import Requests from "../../../../../utils/Requests"

const getDefaultState = () => {
    return {
        updating: false,
        showError: false,
        errors: {},
        alertError: {}
    }
}

const startProcessing = instance => {

    let data = {
        token: instance.props.match.params.token,
        status: 1
    }

    Requests(instance.props)
        .post()
        .withUrl("/vendor/order/authorization/update/status")
        .withData(data)
        .then(response => {
            handleStartProcessingResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleStartProcessingResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                updating: false,
            })
            instance.props.onSuccess()
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        updating: false
    })
    instance.props.onError(error)
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(),
        startProcessing: () => startProcessing(instance)
    }
}

export default Service