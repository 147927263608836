import React from 'react'

const Form = props => {
    return (
        <form onSubmit={props.onSubmit}>
            <div className="inputs">
                <div className="row" id="receipt">
                    <div className="form-input">
                        <input type="text" name="receipt" placeholder="Receipt No" onChange={props.onChange} />
                        <span className="error">{props.errors.receipt}</span>
                    </div>
                </div>
            </div>
            <div className="triggers">
                <button type="submit">Update</button>
                <button type="reset" onClick={props.onCancel}>Cancel</button>
            </div>
        </form>
    )
}

export default Form