import React from 'react'
import NumberFormat from 'react-number-format'
import Dialog from '../../../../components/dialog/dialog.component'
import UploadReceipt from './upload-receipt/upload-receipt.component'
import StatusLabels from '../data/status.labels'
import Service from './electricity.service'
import Loading from '../../../../components/loading/loading.component'
import AlertDialog from '../../../../components/dialog/alert/alert.component'
import { withRouter } from 'react-router-dom'
import StartProcessing from './start-processing/start-processing.component'

class ElectricityOrder extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    toggleShowUploadReceipt = () => {
        this.setState({
            ...this.state,
            showUploadReceipt: !this.state.showUploadReceipt
        })
    }

    toggleShowStartProcessing = () => {
        this.setState({
            ...this.state,
            showStartProcessing: !this.state.showStartProcessing
        })
    }

    onDownloadReceipt = e => {
        e.preventDefault()
        this.setState({
            ...this.state,
            loadingReceipt: true
        }, this.service.loadReceipt)
    }

    onStartProcessingSuccess = () => {
        this.setState({
            ...this.state,
            showStartProcessing: false,
            pending: this.state.pending - 1,
            processing: this.state.processing + 1
        }, () => this.props.onStatusChanged(1))
    }

    onStartProcessingError = error => {
        this.setState({
            ...this.state,
            showStartProcessing: false
        }, () => alert(error))
    }

    getTypeLabel = type => type === 0 ? "Prepaid" : "Postpaid"

    render() {
        return (
            <div className="electricity">
                {this.state.loadingReceipt && <Dialog><Loading /></Dialog>}
                {this.state.showError && <Dialog><AlertDialog {...this.state.alertError} /></Dialog>}
                {this.state.showUploadReceipt && <Dialog><UploadReceipt onCancel={this.toggleShowUploadReceipt} onUploaded={this.props.onReload} /></Dialog>}
                {this.state.showStartProcessing && <Dialog><StartProcessing onError={this.onStartProcessingError} onSuccess={this.onStartProcessingSuccess} /></Dialog>}
                <h3>BEDC Prepaid/Postpaid</h3>
                <div className="metric">
                    <span>Pending <i className="pending">{this.state.pending}</i></span>
                    <span>Processing <i className="processing">{this.state.processing}</i></span>
                </div>
                <div className="details">
                    <div className="info">
                        <span>Order Number</span>
                        <span>#{this.props.order.number}</span>
                    </div>
                    <div className="info">
                        <span>Customer's Name</span>
                        <span>{this.props.order.customer.firstname} {this.props.order.customer.lastname}</span>
                    </div>
                    <div className="info">
                        <span>Customer's Phone</span>
                        <span>{this.props.order.customer.phone}</span>
                    </div>
                    <div className="info">
                        <span>Meter Name</span>
                        <span>{this.props.order.electricity.meter.name}</span>
                    </div>
                    {
                        this.props.order.status !== 0 &&
                        <div className="info">
                            <span>Meter Number</span>
                            <span><strong>{this.props.order.electricity.meter.number}</strong></span>
                        </div>
                    }
                    <div className="info">
                        <span>Meter Type</span>
                        <span>{this.getTypeLabel(this.props.order.electricity.meter.type)}</span>
                    </div>
                    <div className="info">
                        <span>Amount</span>
                        <NumberFormat value={this.props.order.electricity.amount / 100} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => <span><strong>{value}</strong></span>} />
                    </div>
                    <div className="info">
                        <span>Status</span>
                        <span><strong className={'status-' + this.props.order.status}>{StatusLabels[this.props.order.status]}</strong></span>
                    </div>
                </div>
                {
                    this.props.order.status === 0 && <button onClick={this.toggleShowStartProcessing} className="start-processing">Start Processing</button>
                }
                {
                    (this.props.order.status > 0 && this.props.order.status !== 3) && <button onClick={this.toggleShowUploadReceipt} className="accept">Upload Receipt</button>
                }
                {
                    this.props.order.status === 2 && <button onClick={this.onDownloadReceipt} className="download">Download Receipt</button>
                }
            </div>
        )
    }
}

export default withRouter(ElectricityOrder)