import Requests from "../../../utils/Requests"

const getDefaultState = () => {
    return {
        order: null,
        pending: 0,
        processing: 0,
        showAcceptOrder: false,
        showRejectOrder: false,
        loadingOrder: true
    }
}

const loadOrder = instance => {
    Requests(instance.props)
        .post()
        .withUrl("/vendor/order/authorization")
        .withData({
            token: instance.props.match.params.token
        })
        .then(response => {
            handleLoadOrderResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleLoadOrderResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingOrder: false,
                order: response.data.order,
                pending: response.data.pending,
                processing: response.data.processing
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        loadingOrder: false,
        showAcceptOrder: false,
        showRejectOrder: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: () => loadOrder(instance),
            onClose: () => instance.setState({
                ...instance.state,
                showError: false
            })
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(),
        loadOrder: () => loadOrder(instance)
    }
}

export default Service