import React from 'react'
import NumberFormat from 'react-number-format'

const Detail = props => {
    return (
        <div className="info">
            <span>{props.description}</span>
            <NumberFormat value={props.amount / 100} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => <span>{value}</span>} />
        </div>
    )
}

export default Detail