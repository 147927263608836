import React from 'react'
import NumberFormat from 'react-number-format'
import Dialog from '../../../../components/dialog/dialog.component'
import Update from './update/update.component'
import Detail from './detail/detail.component'
import StatusLabels from '../data/status.labels'

class OrderDetails extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showUpdate: false
        }
    }

    toggleShowUpdate = () => {
        this.setState({
            ...this.state,
            showUpdate: !this.state.showUpdate
        })
    }

    render() {
        return (
            <div className="order-details">
                {this.state.showUpdate && <Dialog><Update onCancel={this.toggleShowUpdate} onReload={this.props.onReload} /></Dialog>}
                <h3>Customer Info</h3>
                <div className="details">
                    <div className="info">
                        <span>Order Number</span>
                        <span>#{this.props.order.number}</span>
                    </div>
                    <div className="info">
                        <span>Name</span>
                        <span>{this.props.order.customer.firstname} {this.props.order.customer.lastname}</span>
                    </div>
                    <div className="info">
                        <span>Phone</span>
                        <span>{this.props.order.customer.phone}</span>
                    </div>
                </div>
                <br />
                <h3>Order Details</h3>
                <div className="details">
                    {
                        this.props.order.orderLines.map((orderLine, key) => <Detail key={key} {...orderLine} />)
                    }

                    <div className="info">
                        <span>Amount</span>
                        <NumberFormat value={this.props.order.amount / 100} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => <span><strong>{value}</strong></span>} />
                    </div>
                    <div className="info">
                        <span>Status</span>
                        <span><strong>{StatusLabels[this.props.order.status]}</strong></span>
                    </div>
                </div>
                <br/>
                <h3>Delivery Option</h3>
                <div className="details">
                    <div className="info">
                        <span>{this.props.order.delivery.deliveryOption.name}</span>
                        <span><NumberFormat value={this.props.order.delivery.amount / 100} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => <span><strong>{value}</strong></span>} /></span>
                    </div>
                </div>
                <br/>
                <h3>Delivery Address</h3>
                <div className="details">
                    <div className="info">
                        <span>Contact Name</span>
                        <span>{this.props.order.delivery.contactName}</span>
                    </div>
                    <div className="info">
                        <span>Contact Phone</span>
                        <span>{this.props.order.delivery.contactPhone}</span>
                    </div>
                    <div className="info">
                        <span>Address</span>
                        <span>{this.props.order.delivery.location}</span>
                    </div>
                    <div className="info">
                        <span></span>
                        <span>{this.props.order.delivery.landmark}</span>
                    </div>
                    <div className="info">
                        <span></span>
                        <span>{this.props.order.delivery.area}, {this.props.order.delivery.route}</span>
                    </div>
                </div>
                {
                    this.props.order.status < 2 && <button onClick={this.toggleShowUpdate} className="accept">Update</button>
                }
            </div>
        )
    }
}

export default OrderDetails