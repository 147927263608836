import React from 'react'
import { withRouter } from 'react-router'
import iconLogo from '../../assets/icons/logo.svg'
import './splash.styles.scss'

const Splash = () => {
    return(
        <div className="splash">
            <img alt="" src={iconLogo} />
            <p>
                from
                <strong>Skysenx</strong>
            </p>
        </div>
    )
}

export default withRouter(Splash)