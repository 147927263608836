import React from 'react'
import './header.styles.scss'
import imageLogo from '../../assets/icons/logo-only.svg'

const Header = props => {
    return (
        <header className="app">
            <p>{props.title}</p>
            <img alt="logo" src={imageLogo} />
        </header>
    )
}

export default Header