import Requests from "../../../../../utils/Requests"
import { FileValidationRule, StringValidationRule } from "../../../../../data/validation/rules"
import Validation from "../../../../../data/validation/validation"

const getDefaultState = () => {
    return {
        file: null,
        token: "",
        autoExtractToken: true,
        uploading: false,
        showError: false,
        errors: {},
        alertError: {}
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.token, (error) => errors.token = error, { min: { value: 0, error: "Invalid" }, max: { value: 20, error: "Invalid" } })
    validation.addValidationRule(FileValidationRule, instance.state.file, (error) => errors.file = error, { allowNull: false })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const uploadReceipt = instance => {
    let formData = new FormData()
    formData.append('file', instance.state.file)
    formData.append('token', instance.state.token)
    formData.append('autoExtractToken', instance.state.autoExtractToken)
    formData.append('authorization', instance.props.match.params.token)

    Requests(instance.props)
        .post()
        .withUrl("/vendor/order/authorization/electricity/receipt")
        .withData(formData)
        .then(response => {
            handleUploadReceiptResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleUploadReceiptResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                uploading: false,
            })
            instance.props.onUploaded()
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        uploading: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Okay",
            onClick: () => instance.setState({
                ...instance.state,
                showError: false
            }),
            onClose: () => instance.setState({
                ...instance.state,
                showError: false
            })
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(),
        uploadReceipt: () => uploadReceipt(instance),
        isValid: () => isValid(instance)
    }
}

export default Service