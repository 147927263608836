import Requests from "../../../../utils/Requests"

const getDefaultState = instance => {
    return {
        showUploadReceipt: false,
        showStartProcessing: false,
        loadingReceipt: false,
        pending: instance.props.pending,
        processing: instance.props.processing,
        showError: false,
        alertError: {}
    }
}

const loadReceipt = instance => {
    Requests(instance.props)
        .post()
        .withUrl("/vendor/order/utility/electricity/receipt")
        .withData({
            authorization: instance.props.match.params.token
        })
        .then(response => {
            handleLoadReceiptResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleLoadReceiptResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingReceipt: false
            })
            window.open(response.data.receiptURL)
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        showUploadReceipt: false,
        showStartProcessing: false,
        loadingReceipt: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: () => loadReceipt(instance),
            onClose: () => instance.setState({
                ...instance.state,
                showError: false
            })
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        loadReceipt: () => loadReceipt(instance)
    }
}

export default Service