import React from 'react'
import { withRouter } from 'react-router-dom'
import Header from '../../../components/header/header.component'
import './order.styles.scss'
import Service from './order.service'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'
import AlertDialog from '../../../components/dialog/alert/alert.component'
import ElectricityOrder from './electricity/electricity.component'
import RejectOrder from './components/reject/reject.component'
import CabletvOrder from './cabletv/cabletv.component'
import Details from './details/details.component'

class Order extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        this.service.loadOrder()
    }

    onReload = () => this.setState({
        ...this.state,
        loadingOrder: true,
        showRejectOrder: false,
    }, () => this.service.loadOrder())

    onStatusChanged = status => this.setState({
        ...this.state,
        order: {
            ...this.state.order,
            status: status
        }
    })

    toggleShowRejectOrder = () => {
        this.setState({
            ...this.state,
            showRejectOrder: !this.state.showRejectOrder
        })
    }

    getComponent = order => {
        switch (order.type) {
            case 1:
                return <CabletvOrder order={this.state.order} onReload={this.onReload} />
            case 2:
                return <ElectricityOrder pending={this.state.pending} processing={this.state.processing} order={this.state.order} onReload={this.onReload} onStatusChanged={this.onStatusChanged} />
            case 3:
                return <Details order={this.state.order} onReload={this.onReload} />
            default:
                return <></>
        }
    }

    render() {
        if (this.state.loadingOrder) {
            return (
                <Dialog><Loading /></Dialog>
            )
        }
        else if (this.state.showError) {
            return (
                <AlertDialog {...this.state.alertError} />
            )
        }
        return (
            <>
                <Header title={this.state.order.vendor.name} />
                <div className="order">
                    {this.state.showRejectOrder && <Dialog><RejectOrder onCancel={this.toggleShowRejectOrder} onReload={this.onReload} /></Dialog>}
                    {this.getComponent(this.state.order)}
                    {this.state.order.cancellationReason !== null && <>
                        <br />
                        <strong>Cancellation Reason:</strong>
                        <p>{this.state.order.cancellationReason}</p>
                    </>}
                    {
                        this.state.order.status < 2 && <button onClick={this.toggleShowRejectOrder} className="reject">Cancel Order</button>
                    }
                </div>
            </>
        )
    }
}

export default withRouter(Order)