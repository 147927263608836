const DefaultStore = {
    activeItem: 0,
    items: [
        {
            text: "Home",
            url: "/dashboard"
        },
        {
            text: "Orders",
            url: "/order/all"
        },
        {
            text: "Wallet",
            url: "/wallet"
        },
        {
            text: "Settings",
            url: "/settings"
        }
    ]
}

export default DefaultStore