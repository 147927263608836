import React from 'react'
import Service from './update-receipt.service'
import Dialog from '../../../../../components/dialog/dialog.component'
import Loading from '../../../../../components/loading/loading.component'
import './update-receipt.styles.scss'
import { withRouter } from 'react-router'
import AlertDialog from '../../../../../components/dialog/alert/alert.component'
import FormHelper from '../../../../../utils/FormHelper'
import Form from './form.component'

class UpdateReceipt extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    onSubmit = e => {
        e.preventDefault()
        this.service.isValid() && this.setState({
            ...this.state,
            updating: true
        }, () => this.service.updateReceipt())
    }

    render() {
        return (
            <div className="update-receipt">
                <h3>Update Receipt</h3>
                {this.state.updating && <Dialog><Loading /></Dialog>}
                {this.state.showError && <AlertDialog {...this.state.alertError}/>}
                <Form {...this.state} onChange={FormHelper(this).onChange} onSubmit={this.onSubmit} onCancel={this.props.onCancel}/>
            </div>
        )
    }
}

export default withRouter(UpdateReceipt)