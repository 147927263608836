import React from 'react'
import Service from './start-processing.service'
import Loading from '../../../../../components/loading/loading.component'
import { withRouter } from 'react-router'

class StartProcessing extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        this.setState({
            ...this.state,
        }, () => this.service.startProcessing())
    }

    render() {
        return (
            <Loading />
        )
    }
}

export default withRouter(StartProcessing)