import React from 'react'
import Service from './update.service'
import Dialog from '../../../../../components/dialog/dialog.component'
import Loading from '../../../../../components/loading/loading.component'
import './update.styles.scss'
import { withRouter } from 'react-router'
import AlertDialog from '../../../../../components/dialog/alert/alert.component'

class Update extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    onUpdate = status => {
        this.setState({
            ...this.state,
            updating: true
        }, () => this.service.updateStatus(status))
    }

    render() {
        return (
            <div className="update">
                <h3>Update Order</h3>
                {this.state.updating && <Dialog><Loading /></Dialog>}
                {this.state.showError && <AlertDialog {...this.state.alertError} />}
                <div className="triggers">
                    <button onClick={() => this.onUpdate(1)}>Processing</button>
                    <button onClick={() => this.onUpdate(2)}>Processed</button>
                    <button onClick={this.props.onCancel}>Cancel</button>
                </div>
            </div>
        )
    }
}

export default withRouter(Update)